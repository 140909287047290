.fh {
  min-height: 100% !important;
  height: 100% !important;
}

.fw {
  width: 100% !important;
}

.ce {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes placeHolderShimmer{
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

// Input required
.lr {
  &:after {
    content: "*";
    color: red;
  }
}

// Monospace font
.mnsp {
  font-family: monospace;
}

.curpt {
  cursor: pointer;
}

.imgg {
  max-height: 100%;
  max-width: 100%;
}

.tableRowCursor tr {
  cursor: pointer;
}
