html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#mount {
  min-height: 100%;
  height: 100%;
}

em {
  font-weight: bold !important;
  font-style: normal !important;
}
