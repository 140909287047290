// All buttons
button:focus, a:focus {
  outline: 0 !important;
}

// react-big-calendar
.rbc-btn-group {
  button {
    padding: .3rem .5rem;
    font-size: .9rem;
  }
}

.react-big-calendar-hide-all-day-row {
  .rbc-allday-cell {
    display: none;
  }
}

// Antd page header

.ant-page-header-heading {
  display: flex;

  .ant-page-header-heading-title {
    flex: inherit;
  }

  .ant-page-header-heading-extra {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-page-header-heading-extra > * {
    margin-left: 0;
    margin-right: 8px;
  }

  .ant-page-header-heading-extra > *:first-child {
    margin-right: 0;
  }

  .ant-page-header-heading-extra > *:last-child {
    margin-right: 8px;
  }
}

// Antd sub menu
li.ant-menu-submenu {
  border-bottom: 1px solid #002446;
}
